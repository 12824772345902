import React from "react";

const ProjectIcon = () => {
    return (
        <>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.6667 2.66672H9.88C9.74349 2.27701 9.48925 1.93936 9.15245 1.70047C8.81564 1.46157 8.41292 1.33325 8 1.33325C7.58708 1.33325 7.18436 1.46157 6.84755 1.70047C6.51075 1.93936 6.25651 2.27701 6.12 2.66672H3.33333C2.98004 2.66778 2.64151 2.80859 2.39169 3.05841C2.14187 3.30823 2.00106 3.64676 2 4.00005V13.3334C2.00106 13.6867 2.14187 14.0252 2.39169 14.275C2.64151 14.5249 2.98004 14.6657 3.33333 14.6667H12.6667C13.02 14.6657 13.3585 14.5249 13.6083 14.275C13.8581 14.0252 13.9989 13.6867 14 13.3334V4.00005C13.9989 3.64676 13.8581 3.30823 13.6083 3.05841C13.3585 2.80859 13.02 2.66778 12.6667 2.66672ZM8 2.66672C8.13185 2.66672 8.26075 2.70582 8.37038 2.77907C8.48001 2.85233 8.56546 2.95645 8.61592 3.07826C8.66638 3.20008 8.67958 3.33413 8.65386 3.46345C8.62813 3.59277 8.56464 3.71156 8.4714 3.80479C8.37817 3.89803 8.25938 3.96152 8.13006 3.98724C8.00074 4.01297 7.86669 3.99977 7.74488 3.94931C7.62306 3.89885 7.51894 3.8134 7.44569 3.70377C7.37243 3.59413 7.33333 3.46524 7.33333 3.33339C7.33386 3.15674 7.40427 2.98748 7.52918 2.86257C7.65409 2.73766 7.82335 2.66725 8 2.66672ZM6.66667 6.00005L8.52667 7.86272L10.2067 6.18272L9.33333 5.33339H12V8.00005L11.1493 7.12605L8.528 9.74939L6.66667 7.88672L4.94267 9.60939L4 8.66672L6.66667 6.00005ZM12.6667 12.6667H3.33333V11.3334H12.6667V12.6667Z"
                    fill="white"
                />
            </svg>
        </>
    );
};

export default ProjectIcon;
