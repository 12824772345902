import SideMenuLogo from "assets/Icons/icons/Logo";
import SearchInput from "components/common/SearchInput";
import ProjectList from "components/list-project";
import React, { useState } from "react";

const SideMenu = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedProject, setSelectedProject] = useState("");

    const projects = [
        "Moonshot",
        "ADO-Sync-Test",
        "Moonshot",
        "Moonshot",
        "Moonshot",
        "Moonshot",
        "Moonshot",
        "Moonshot",
        "Moonshot",
    ];

    const filteredProjects = projects.filter((project) =>
        project.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="w-[21%] border-r border-[#D9D9D9] h-screen">
            <div className="p-4">
                <div className="text-[13px] font-semibold flex items-center  gap-4">
                    <div className="bg-[#009BC0] text-[#fff] leading-4 p-[6px] rounded-md">
                        PR
                    </div>
                    <div>
                        <p className="text-[16px] text-[#262626] leading-4">
                            Projects
                        </p>
                        <p className="font-medium text-[13px] text-[#8C8C8C] leading-4 mt-[6px]">
                            200 projects
                        </p>
                    </div>
                </div>
            </div>

            <div>
                <h1 className="text-lg font-bold mb-4">Available Projects</h1>
                <SearchInput
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
                <ProjectList
                    projects={filteredProjects}
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                />
            </div>
        </div>
    );
};

export default SideMenu;
