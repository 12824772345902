import ProjectItem from "components/common/ProjectItem";
import React from "react";

const ProjectList = ({ projects, selectedProject, setSelectedProject }) => {
    return (
        <>
            <div>
                {projects.map((project, index) => (
                    <ProjectItem
                        key={index}
                        name={project}
                        isSelected={selectedProject === project}
                        onClick={() => setSelectedProject(project)}
                    />
                ))}
            </div>
        </>
    );
};

export default ProjectList;
