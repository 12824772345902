import CommunityIcon from "assets/Icons/icons/CommunityIcon";
import DarkModeIcon from "assets/Icons/icons/DarkModeIcon";
import DocsIcon from "assets/Icons/icons/DocsIcon";
import SideMenuLogo from "assets/Icons/icons/Logo";
import SupportIcon from "assets/Icons/icons/SupportIcon";
import ItemContainer from "components/common/ItemContainer";
import React from "react";

const TopBar = () => {
    return (
        <div className="border-b border-[#D9D9D9] w-full sticky top-0 flex">
            <div className="w-[21%] p-4 border-r border-[D9D9D9]">
                <SideMenuLogo />
            </div>
            <div className="flex items-center py-5 justify-end w-[79%] pr-16 ">
                <div className="flex items-center gap-2">
                    <ItemContainer
                        icon={<DocsIcon />}
                        title={"Docs"}
                        style={"bg-[#F0F0F0]"}
                    />
                    <ItemContainer
                        icon={<CommunityIcon />}
                        title={"Community"}
                        style={"bg-[#F0F0F0]"}
                    />
                    <ItemContainer
                        icon={<SupportIcon />}
                        title={"Support"}
                        style={"bg-[#F0F0F0]"}
                    />
                </div>
                <div className="h-full border border-[#F0F0F0] ml-1 mr-2 " />
                <ItemContainer
                    icon={<DarkModeIcon />}
                    title={"Light Mode"}
                    style={"bg-[#F0F0F0]"}
                />
            </div>
        </div>
    );
};

export default TopBar;
