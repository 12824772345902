import React from "react";

const SupportIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.13333 2.9551L8.0125 6.83343C8.60843 6.45949 9.29771 6.26114 10.0013 6.26114C10.7048 6.26114 11.3941 6.45949 11.99 6.83343L15.8667 2.95676C14.2197 1.58525 12.1441 0.834229 10.0008 0.834229C7.85754 0.834229 5.78201 1.58525 4.135 2.95676M17.045 4.13343L13.1667 8.0126C13.5404 8.60844 13.7387 9.29756 13.7387 10.0009C13.7387 10.7043 13.5404 11.3934 13.1667 11.9893L17.0442 15.8668C18.4157 14.2198 19.1667 12.1442 19.1667 10.0009C19.1667 7.85764 18.4157 5.78211 17.0442 4.1351M15.8667 17.0451L11.9892 13.1668C11.3932 13.5407 10.704 13.7391 10.0004 13.7391C9.29688 13.7391 8.6076 13.5407 8.01167 13.1668L4.13333 17.0459C5.78041 18.4177 7.85611 19.1688 9.99958 19.1688C12.1431 19.1688 14.2188 18.4177 15.8658 17.0459M2.95583 15.8668L6.83333 11.9876C6.45956 11.3918 6.2613 10.7026 6.2613 9.99926C6.2613 9.2959 6.45956 8.60677 6.83333 8.01093L2.95583 4.13343C1.58432 5.78044 0.833298 7.85598 0.833298 9.99926C0.833298 12.1426 1.58432 14.2181 2.95583 15.8651"
                    fill="#595959"
                />
            </svg>
        </>
    );
};

export default SupportIcon;
