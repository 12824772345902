import MarkIcon from "assets/Icons/icons/MarkIcon";
import ProjectIcon from "assets/Icons/icons/ProjectIcon";
import React from "react";

const ProjectItem = ({ name, isSelected, onClick }) => {
    return (
        <div
            className={`flex items-center justify-between py-3 px-4 cursor-pointer ${
                isSelected ? "bg-[#FCF2E8] rounded-lg" : "bg-transparent"
            }`}
            onClick={onClick}
        >
            <div className="flex gap-4 items-center">
                <div className="p-1 bg-[#009BC0] rounded">
                    <ProjectIcon />
                </div>
                <p className="font-medium text-[15px] leading-5 text-[#454545]">
                    {name}
                </p>
            </div>

            {isSelected && (
                <div>
                    <MarkIcon />
                </div>
            )}
        </div>
    );
};

export default ProjectItem;
