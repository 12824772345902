import React from "react";

const MarkIcon = () => {
    return (
        <>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="3.5"
                    fill="#E37915"
                />
                <rect
                    x="0.5"
                    y="0.5"
                    width="19"
                    height="19"
                    rx="3.5"
                    stroke="#E37915"
                />
                <path
                    d="M15.9998 6.19995L8.2998 13.9L4.7998 10.4"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    );
};

export default MarkIcon;
