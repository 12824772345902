import SideMenu from "components/sidemenu/SideMenu";
import TopBar from "components/topBar/TopBar";
import React from "react";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
    return (
        <div className="bg-white">
            <TopBar />
            <div>
                <SideMenu />
                <div>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
