import DashboardLayout from "layouts/DashboardLayout";
import MainCloudCounter from "pages/cloud-counter";
import ExportAndCount from "pages/mainHome";
import React from "react";
import { Route, Routes } from "react-router-dom";

const Router = () => {
    return (
        <Routes>
            <Route path="" element={<DashboardLayout />}>
                <Route path="/">
                    <Route path="" element={<ExportAndCount />} />
                </Route>

                <Route path="cloud-counter" element={<MainCloudCounter />} />
            </Route>
        </Routes>
    );
};

export default Router;
