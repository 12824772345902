import React from "react";

const SearchInput = ({ searchQuery, setSearchQuery }) => {
    return (
        <div className="relative p-2">
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full p-2 border rounded"
                placeholder="Search projects"
            />
            <span className="absolute right-3 top-3 text-gray-500">⌘K</span>
        </div>
    );
};

export default SearchInput;
